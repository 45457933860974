import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  contacts: {
    byId: {},
    allIds: []
  }, 
  recipients: [],
  numbers: []
};

const slice = createSlice({
  name: 'conf',
  initialState,
  reducers: {
    getContacts(state, action) {
      const { contacts } = action.payload;

      state.contacts.byId = objFromArray(contacts);
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },  
    getNumbers(state, action) {
      const { numbers } = action.payload;

      state.numbers = numbers;
    },  
    clearRecipients(state) {
        state.recipients = [];
      }, 
    addRecipient(state, action) {
      const { recipient } = action.payload;
      const exists = state.recipients.find((_recipient) => _recipient.id === recipient.id);

      if (!exists) {
        state.recipients.push(recipient);
      }
    },
    removeRecipient(state, action) {
      const { recipientId } = action.payload;

      state.recipients = state.recipients.filter((recipient) => recipient.id !== recipientId);
    }
  }
});

export const reducer = slice.reducer;

export const getContacts = () => async (dispatch) => {
  const response = await axios.get('/api/contacts');

  dispatch(slice.actions.getContacts(response.data));
};
 

export const getNumbers = () => async (dispatch) => {
  const response = await axios.get('/api/numbers');
  dispatch(slice.actions.getNumbers(response.data));
}; 
 
export const addRecipient = (recipient) => (dispatch) => {
  dispatch(slice.actions.addRecipient({ recipient }));
};

export const removeRecipient = (recipientId) => (dispatch) => {
  dispatch(slice.actions.removeRecipient({ recipientId }));
};

export default slice;

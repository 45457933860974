import React, { useEffect , useRef} from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import Features from './Features';
import Testimonials from './Testimonials';
import CTA from './CTA';
import FAQS from './FAQS';

const useStyles = makeStyles(() => ({
  root: {}
})); 

const HomeView = () => {
  const classes = useStyles();
  const ref = useRef();
  // const faq = React.createRef();
  // useEffect(() => {
  //   console.log('sdfdsfsdfsd');
  //   const executeScroll = () => faq.current.scrollIntoView() 
  //     }, [ref]);
      
  return (
    <Page
      className={classes.root}
      title="Home"
    >
      <Hero  />
      <Features />
      <CTA />
      {/* <Testimonials /> */}
      {/* <FAQS /> */}
    </Page>
  );
};

export default HomeView;

/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  User,
  Phone,
  PhoneCall
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { LibraryAddCheck, LibraryBooks, MessageOutlined, PhoneAndroidTwoTone, PlayArrow, Star, VoiceChat, Voicemail } from '@material-ui/icons';
import { useDispatch, useSelector } from 'src/store';

const adminSections = [
  {
    subheader: 'Management',
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/admin/management/customers',
      },
      // {
      //   title: 'Products',
      //   icon: ShoppingCartIcon,
      //   href: '/admin/management/products',
      //   items: [
      //     {
      //       title: 'List Products',
      //       href: '/admin/management/products'
      //     },
      //     {
      //       title: 'Create Product',
      //       href: '/admin/management/products/create'
      //     }
      //   ]
      // },
      {
        title: 'Plans',
        icon: Star,
        href: '/admin/management/pricing',
        // items: [
        //   {
        //     title: 'Basic',
        //     href: '/admin/management/orders'
        //   },
        //   {
        //     title: 'Premium',
        //     href: '/admin/management/orders/1'
        //   }
        // ]
      },
      {
        title: 'Numbers',
        icon: PhoneAndroidTwoTone,
        href: '/admin/numbers'
      },
      // {
      //   title: 'Invoices',
      //   icon: ReceiptIcon,
      //   href: '/admin/management/invoices',
      //   items: [
      //     {
      //       title: 'List Invoices',
      //       href: '/admin/management/invoices'
      //     },
      //     {
      //       title: 'Pending Invoice',
      //       href: '/admin/management/invoices/1'
      //     }
      //   ]
      // }
    ]
  },
]

const sections = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/reports/dashboard'
      },
      {
        title: 'Call Logs',
        icon: PieChartIcon,
        href: '/app/incomingLogs'
      },
      {
        title: 'Voicemail',
        icon: Voicemail,
        href: '/app/voicemail'
      },
    ]
  }, {
    subheader: 'Activities',
    items: [
      {
        title: 'Conferences',
        icon: PhoneCall,
        href: '/app/management/conferences'
        // items: [
        //   {
        //     title: 'List Conferences',
        //     href: '/app/management/conferences'
        //   },
        //   {
        //     title: 'Create Conferences',
        //     href: '/app/management/conferences/create'
        //   }
        // ]
      },
      {
        title: 'Contacts',
        icon: User,
        href: '/app/contacts',
        // items: [
        //   {
        //     title: 'List Contacts',
        //     href: '/app/contacts'
        //   },
        //   {
        //     title: 'Create contact',
        //     href: '/app/contacts/add'
        //   }
        // ]
      },
      {
        title: 'Groups',
        icon: UsersIcon,
        href: '/app/groups',
        // items: [
        //   {
        //     title: 'List Groups',
        //     href: '/app/groups'
        //   },
        //   {
        //     title: 'Create Group',
        //     href: '/app/groups/create'
        //   },
        // ]
      },
    ]
  },
  {
    subheader: 'Applications',
    items: [

      {
        title: 'Flows',
        icon: PlayArrow,
        href: '/app/flows'
      },
      {
        title: 'Chat',
        href: '/app/chat',
        icon: MessageCircleIcon,
        info: GetTotalUnreadCount
      },
      {
        title: 'NPX SMS',
        href: '/app/npx',
        icon: MessageOutlined,
      },
    ]
  },
  {
    subheader: 'Configuration',
    items: [
      {
        title: 'Numbers',
        icon: PhoneAndroidTwoTone,
        href: '/app/numbers'
      },

    ]
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        {/* <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              Your tier:
              {' '}
              <Link
                component={RouterLink}
                to="/pricing"
              >
                {user.tier}
              </Link>
            </Typography>
          </Box>
        </Box> */}
        <Divider />
        {user.tier === 'AdminTire' ? (
          <Box p={2}>
            {adminSections.map((section) => (
              <List
                key={section.subheader}
                subheader={(
                  <ListSubheader
                    disableGutters
                    disableSticky
                  >
                    {section.subheader}
                  </ListSubheader>
                )}
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
          </Box>
        ) :
          <Box>
            <Box p={2}>
              {sections.map((section) => (
                <List
                  key={section.subheader}
                  subheader={(
                    <ListSubheader
                      disableGutters
                      disableSticky
                    >
                      {section.subheader}
                    </ListSubheader>
                  )}
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname
                  })}
                </List>
              ))}
            </Box>
            <Divider />

            <Box p={2}>
              <Box
                p={2}
                borderRadius="borderRadius"
                bgcolor="background.dark"
              >
                <Typography
                  variant="h6"
                  color="textPrimary"
                >
                  Need Help?
            </Typography>
                <Link
                  variant="subtitle1"
                  color="secondary"
                  component={RouterLink}
                  to="#"
                // to="/docs"
                >
                  Contact Us
            </Link>
              </Box>
            </Box>
          </Box>
        }
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;

const GetTotalUnreadCount = () => {
  const { threads } = useSelector((state) => state.chat);
  if (threads) {
    var byIdArray = Object.keys(threads.byId).map((key) => [key, threads.byId[key]]);
    var unreadCount = 0;
    byIdArray.map((thread) => {
      unreadCount = unreadCount + thread.unreadCount;
    })
  }
  console.log('unreadCount', unreadCount);
  if (unreadCount === 0) {
    return () => (<Chip
      color="secondary"
      size="small"
      label="0"
    />)
  } else {
    return () => (<Chip
      color="secondary"
      size="small"
      label={unreadCount}
    />)
  }
}


import React, {
    createContext,
    useEffect,
    useReducer
} from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';
import { useSnackbar } from 'notistack';

const initialAuthState = {
    isAuthenticated: true,
    isInitialised: false,
    user: null
};

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INITIALISE': {
            const { isAuthenticated, user } = action.payload;

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user
            };
        }
        case 'LOGIN': {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        }
        case 'REGISTER': {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    method: 'JWT',
    initialise: () => Promise.resolve(),
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    const { enqueueSnackbar } = useSnackbar();

    const login = async (email, password) => {
        // const response = await axios.post('/api/account/login', { email, password });
        const response = await axios.post('/api/users/login', { email, password });
        const accessToken = response.data.token;
        const user = response.data.user;
        setSession(accessToken);
        dispatch({
            type: 'LOGIN',
            payload: {
                user
            }
        });
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: 'LOGOUT' });
    };

    const refresh = () => {
        setSession(null);
        dispatch({ type: 'LOGOUT' });
    };

    const register = async (email, name, password, password2) => {
        const response = await axios.post('/api/users/register', {
            email,
            name,
            password,
            password2
        });
        if (response.status === 201) {
            enqueueSnackbar(response.data.msg, {
                variant: 'warning'
            });
            dispatch({
                type: 'INITIALISE',
                payload: {
                    isAuthenticated: false,
                    user: null
                }
            });
        } else {
            const accessToken = response.data.token;
            const user = response.data.user;
            setSession(accessToken);
            // const { accessToken, user } = response.data;

            // window.localStorage.setItem('accessToken', accessToken);

            dispatch({
                type: 'REGISTER',
                payload: {
                    user
                }
            });
        }
    };

    const initialise = async () => {
        try {
            const accessToken = window.localStorage.getItem('accessToken');

            if (accessToken && isValidToken(accessToken)) {
                setSession(accessToken);

                const response = await axios.get('/api/users/me');
                const { user } = response.data;

                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isAuthenticated: true,
                        user
                    }
                });
            } else {
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: 'INITIALISE',
                payload: {
                    isAuthenticated: false,
                    user: null
                }
            });
        }
    };

    useEffect(() => {
        initialise();
    }, []);

    if (!state.isInitialised) {
        return <SplashScreen />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                initialise,
                login,
                logout,
                register
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
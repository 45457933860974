import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth(); 
  if (isAuthenticated) {
    var path = window.location.pathname.split("/")[1];
    if (user.tier !== 'AdminTire' && path === 'admin') {
      return <Redirect to="/404" />;
    }   
  } 

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;

// import React from 'react';
import React, {
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Button,
  Drawer,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
// import handleOpen from './Dialer'
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
// import Contacts from './Contacts';
import Notifications from './Notifications';
import Dialer from './Dialer';
import Settings from './Settings';
// import axios from 'src/utils/axios';

// import PerfectScrollbar from 'react-perfect-scrollbar';
// import {
//   PhoneCall,
//   XCircle as XIcon
// } from 'react-feather';
// import { PhoneEnabled } from '@material-ui/icons';
// import {
//   getNumbers,
// } from 'src/slices/chat';
// import { useDispatch, useSelector } from 'src/store';
// import * as Yup from 'yup';
// import { Formik } from 'formik';

// const { Device } = require('twilio-client');


// function useInterval(callback, delay) {
//   const savedCallback = useRef();

//   // Remember the latest function.
//   useEffect(() => {
//     savedCallback.current = callback;
//   }, [callback]);

//   // Set up the interval.
//   useEffect(() => {
//     function tick() {
//       savedCallback.current();
//     }
//     if (delay !== null) {
//       let id = setInterval(tick, delay);
//       return () => clearInterval(id);
//     }
//   }, [delay]);
// }

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  }, 
  logo: {
    marginRight: theme.spacing(2),
    height: 64
  },
  toolbar: {
    minHeight: 64
  },
  drawer: {
    width: 300,
    maxWidth: '100%'
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();  

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo className={classes.logo}/>
          </RouterLink>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        <Dialer />
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => { }
};

export default TopBar;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    Container,
    Grid,
    Typography,
    makeStyles,
    IconButton,
    SvgIcon
} from '@material-ui/core';
import { Call, Chat, Sms } from '@material-ui/icons'; 

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingTop: 200,
        paddingBottom: 200,
        [theme.breakpoints.down('md')]: {
            paddingTop: 60,
            paddingBottom: 60
        }
    },
    technologyIcon: {
        height: 40,
        margin: theme.spacing(1)
    },
    image: {
        perspectiveOrigin: 'left center',
        transformStyle: 'preserve-3d',
        perspective: 1500,
        '& > img': {
            maxWidth: '90%',
            height: 'auto',
            transform: 'rotateY(-35deg) rotateX(15deg)',
            backfaceVisibility: 'hidden',
            boxShadow: theme.shadows[16]
        }
    },
    shape: {
        position: 'absolute',
        top: 0,
        left: 0,
        '& > img': {
            maxWidth: '90%',
            height: 'auto'
        }
    }
}));

const Hero = ({ className,refs, ...rest }) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Container maxWidth="lg">
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        xs={12}
                        md={5}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            height="100%"
                        >
                            <Typography
                                variant="overline"
                                color="secondary"
                            >
                                Introducing
              </Typography>
                            <Typography
                                variant="h1"
                                color="textPrimary"
                            >
                                voxVybe
              </Typography>
                            <Box mt={3}>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                >
                                    A  Bussiness Callcenter solution for all purposes , it has all required communicational features

                </Typography>
                            </Box>
                            <Box mt={3}>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid item>
                                        <Typography
                                            variant="h1"
                                            color="secondary"
                                        >
                                            Conference
                    </Typography>
                                        <Typography
                                            variant="overline"
                                            color="textSecondary"
                                        >
                                            with routable Numbers
                    </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="h1"
                                            color="secondary"
                                        >
                                            Contacts
                    </Typography>
                                        <Typography
                                            variant="overline"
                                            color="textSecondary"
                                        >
                                            import and export
                    </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="h1"
                                            color="secondary"
                                        >
                                            Web Calling
                    </Typography>
                                        <Typography
                                            variant="overline"
                                            color="textSecondary"
                                        >
                                            all browser compatible
                    </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="h1"
                                            color="secondary"
                                        >
                                            Sms Chat
                    </Typography>
                                        <Typography
                                            variant="overline"
                                            color="textSecondary"
                                        >
                                            with routable numbers
                    </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="h1"
                                            color="secondary"
                                        >
                                            Numbers
                    </Typography>
                                        <Typography
                                            variant="overline"
                                            color="textSecondary"
                                        >
                                            sms/call enabled
                    </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="h1"
                                            color="secondary"
                                        >
                                            IVR
                    </Typography>
                                        <Typography
                                            variant="overline"
                                            color="textSecondary"
                                        >
                                            customizable
                    </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mt={3}>
                                <IconButton
                                    color="inherit"
                                    // onClick={() => { refs.current.scrollIntoView();  console.log('onClick'); }}
                                >
                                    <SvgIcon fontSize="large">
                                        <Call />
                                    </SvgIcon>
                                </IconButton> 
                                <IconButton
                                    color="inherit"
                                >
                                    <SvgIcon fontSize="large">
                                        <Chat />
                                    </SvgIcon>
                                </IconButton>
                                </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <Box position="relative">
                            <div className={classes.shape}>
                                <img
                                    alt="Shapes"
                                    src="/static/home/shapes.svg"
                                />
                            </div>
                            <div className={classes.image}>
                                <img
                                    alt="Presentation"
                                    src="/static/home/dark-light.png"
                                />
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

Hero.propTypes = {
    className: PropTypes.string
};

export default Hero;

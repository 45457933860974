import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import {
    Box,
    Button,
    CircularProgress,
    Drawer,
    IconButton,
    InputAdornment,
    Link,
    SvgIcon,
    FormHelperText,
    TextField,
    Tooltip,
    Typography,
    makeStyles
} from '@material-ui/core';
import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import {
    PhoneCall,
    XCircle as XIcon
} from 'react-feather';
import { PhoneEnabled } from '@material-ui/icons';
import { getNumbers} from 'src/slices/chat';
import { useDispatch, useSelector } from 'src/store';

import clsx from 'clsx';
import * as Yup from 'yup';

import { THEMES } from 'src/constants';
import { Formik } from 'formik';
import axios from 'src/utils/axios';
import useAuth from 'src/hooks/useAuth';

const { Device } = require('twilio-client');

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...theme.name === THEMES.LIGHT ? {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.main
        } : {},
        ...theme.name === THEMES.ONE_DARK ? {
            backgroundColor: theme.palette.background.default
        } : {}
    },
    toolbar: {
        minHeight: 64
    },
    drawer: {
        width: 300,
        maxWidth: '100%'
    }
}));

const Dialer = () => {
    const classes = useStyles();
    const [timerIntval, setTimerIntval] = useState(false);
    const [callIncoming, setCallIncoming] = useState(false);

    const [value, setValue] = useState('');
    const [isOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [call, setCall] = useState(false);
    const [device, setDevice] = useState(false);

    const [times, setTimes] = useState(0);
    const [onCall, setOnCall] = useState(false);
    const dispatch = useDispatch();
    const { numbers } = useSelector((state) => state.chat);
    const { user } = useAuth();
    // var timerIntval; 
    const { enqueueSnackbar } = useSnackbar();
    const getToken = useCallback(async () => {
        try {
            // setLoading(true);
            if (user.tier != 'AdminTire') {
                const response = await axios.get('/api/call/token');
                Device.setup(response.data.token);
            }
            Device.on('incoming', function (connection) {
                //connection.accept();
                setCall(connection);
                setCallIncoming(true);
                handleOpen();
                // Device.disconnectAll();
            });

            Device.on('cancel', function () {
                handleClose();
                setCall(false);
                setCallIncoming(false);
            })
            Device.on('connect', function (connection) {
                setCall(connection);
                setOnCall(true);
                // startTimer();
                // console.log(call.customParameters)
                var i = 0;
                var interval = setInterval(() => {
                    setTimes(i++);
                    console.log(times);
                }, 1000);
                setTimerIntval(interval);

                handleOpen();
            })
            Device.on('disconnect', function () {
                clearInterval(timerIntval);
                handleClose();
                setCall(false);
                // stopTimer();
            })
            Device.on('error', function (errr) {
                console.log(errr);
            })
            Device.on('offline', function () {
                getToken();
            })
            Device.on('ready', function () {
                setDevice(true);
            })
        } catch (err) {
            console.error(err);
            enqueueSnackbar('Something went wrong', {
                variant: 'error'
            });
        }
    }
        // , [isMountedRef]
    );


    useEffect(() => {
        if (!device) {
            getToken();
        }
    }, [getToken]);


    const startTimer = () => {
        // intervalId = setInterval(incrementTimer(), 1000);

    };

    // const incrementTimer = () => {
    //   setTimes(times + 1);
    // };

    const stopTimer = () => {
        setTimes(0);
    };

    const formatTime = (totalSeconds) => {
        var secondValue = totalSeconds % 60;
        var totalMinutes = Math.floor(totalSeconds / 60);
        var minuteValue = totalMinutes % 60;
        var hourValue = Math.floor(totalMinutes / 60);

        return `${hourValue < 10 ? 0 : ''}${hourValue} : ${minuteValue < 10 ? 0 : ''}${minuteValue} : ${secondValue < 10 ? 0 : ''}${secondValue}`;
    };



    const handleOpen = () => {
        dispatch(getNumbers());
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const rejectCall = () => {
        call.reject();
        Device.disconnectAll();
        handleClose();
        setCall(false);
        setCallIncoming(false);
    };

    const hangupCall = () => {
        Device.disconnectAll();
    };
    const answerCall = () => {
        setCallIncoming(false);
        call.accept();
    };

    return (
        <Formik
            initialValues={{
                from: '',
                to: '',
            }}
            validationSchema={Yup.object().shape({
                to: Yup.string().required('required'),
                from: Yup.string().required('required'),
            })}
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting,
                resetForm
            }) => {
                console.log(values);
                try {
                    setLoading(true);
                    // var n = '+' + values.to.replace(/\D/g, '');
                    Device.connect({ number: values.to, from: values.from, uid: user.id });
                    setStatus({ success: true });
                } catch (err) {
                    enqueueSnackbar('Something went wrong', {
                        variant: 'error'
                    });
                    setStatus({ success: false });
                    setErrors({ submit: err.msg });
                } finally {
                    setLoading(false);
                }
                setSubmitting(false);
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                // setFieldValue,
                touched,
                values
            }) => (
                <form
                    onSubmit={handleSubmit}
                    className={clsx(classes.root)}
                // {...rest}
                >
                    {user.tier != 'AdminTire' && <Tooltip title="Call">
                        <IconButton
                            color="inherit"
                            onClick={handleOpen}
                        >
                            <SvgIcon fontSize="small">
                                <PhoneCall />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>}
                    {user.tier != 'AdminTire' && <Drawer
                        anchor="right"
                        classes={{ paper: classes.drawer }}
                        ModalProps={{ BackdropProps: { invisible: true } }}
                        onClose={handleClose}
                        open={isOpen}
                        variant="temporary"
                    >
                        <PerfectScrollbar options={{ suppressScrollX: true }}>
                            <Box p={3}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography
                                        variant="h4"
                                        color="textPrimary"
                                    >
                                        Call
                                    </Typography>
                                    <IconButton onClick={handleClose}>
                                        <SvgIcon fontSize="small">
                                            <XIcon />
                                        </SvgIcon>
                                    </IconButton>
                                </Box>
                                {call ? (
                                    <>
                                        {call.parameters.From ? (
                                            <p>incoming call from {call.parameters.From}</p>
                                        ) : call.customParameters['number'] ? (
                                            <p>Calling {call.customParameters['number']}</p>) : ('')}
                                    </>
                                ) : (
                                    <>
                                        <Box mt={2}>
                                            <TextField
                                                // style={{ width: 400, marginTop: 10 }}
                                                error={Boolean(touched.from && errors.from)}
                                                helperText={touched.from && errors.from}
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SvgIcon
                                                                fontSize="small"
                                                                color="action"
                                                            >
                                                                <PhoneEnabled />
                                                            </SvgIcon>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                label="From"
                                                name="from"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                onChange={handleChange}
                                                SelectProps={{ native: true }}
                                                value={values.from}
                                                variant="outlined"
                                            >
                                                <option
                                                    key={1}
                                                    value=""
                                                >
                                                    From Number
                                                </option>
                                                {numbers.map((number) => (
                                                    <option
                                                        key={number.phoneNumber}
                                                        value={number.phoneNumber}
                                                    >
                                                        {number.phoneNumber}
                                                    </option>
                                                ))}
                                            </TextField>

                                        </Box>
                                        <Box mt={2}>
                                            <TextField
                                                error={Boolean(touched.to && errors.to)}
                                                helperText={touched.to && errors.to}
                                                fullWidth
                                                type="number"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SvgIcon
                                                                fontSize="small"
                                                                color="action"
                                                            >
                                                                <PhoneCall />
                                                            </SvgIcon>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={handleChange}
                                                placeholder="Enter phone number"
                                                name='to'
                                                value={values.to}
                                                variant="outlined"
                                            />
                                        </Box>
                                    </>
                                )}
                                {onCall &&
                                    <Typography>
                                        {formatTime(times)}
                                    </Typography>
                                }

                                <Box
                                    mt={2}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    {callIncoming ? (
                                        <>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={answerCall}
                                            >
                                                Answer
                                            </Button>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={rejectCall}
                                            > Reject </Button>
                                        </>

                                    ) : (
                                        <>
                                            {call ? (
                                                <><Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={hangupCall}
                                                > Hangup </Button></>
                                            ) : (
                                                <>
                                                    <Button
                                                        color="secondary"
                                                        variant="contained"
                                                        onClick={handleSubmit}
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >   Call </Button>
                                                </>)}
                                        </>
                                    )}
                                </Box>
                                {errors.submit && (
                                    <Box mt={3}>
                                        <FormHelperText error>
                                            {errors.submit}
                                        </FormHelperText>
                                    </Box>
                                )}
                            </Box>
                        </PerfectScrollbar>
                    </Drawer>}
                </form>
            )}
        </Formik>
    );
};



export default Dialer;

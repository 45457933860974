import React, {
  useCallback,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import axios from 'src/utils/axios';
import { Link } from 'react-router-dom';
import useIsMountedRef from 'src/hooks/useIsMountedRef'; 
import { useSnackbar } from 'notistack';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    height: '100%',
    paddingTop: 120,
    paddingBottom: 120
  },
  product: {
    position: 'relative',
    padding: theme.spacing(5, 3),
    cursor: 'pointer',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  productImage: {
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: -24,
    left: theme.spacing(3),
    height: 48,
    width: 48,
    fontSize: 24
  },
  recommendedProduct: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  chooseButton: {
    backgroundColor: theme.palette.common.white
  }
}));

const CTA = ({ className, ...rest }) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [plans, setPlans] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const getPlans = useCallback(async () => {
    try {
      const response = await axios.post('/api/users/plans');

      if (isMountedRef.current) {
        setPlans(response.data.plans);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(err.msg, {
        variant: 'error'
      });
    }
  }, [isMountedRef]);

  useEffect(() => {
    getPlans();
  }, [getPlans]);
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
        >
          {plans.map((plan) => {
            return <Grid
              item
              key={plan.id}
              md={6}
              xs={12}
            >
              <Paper
                className={plan.name === 'Basic' ? clsx(classes.product) : clsx(classes.product, classes.recommendedProduct)}
                elevation={1}
              >
                <img
                  alt="Product"
                  className={classes.productImage}
                  src={plan.name === 'Basic' ? "/static/images/products/product_standard.svg" : "/static/images/products/product_premium--outlined.svg"}
                />
                <Typography
                  component="h3"
                  gutterBottom
                  variant="overline"
                  color={plan.name === 'Basic' ? "textSecondary" : "inherit"}
                >
                  {plan.name}
                </Typography>
                <div>
                  <Typography
                    component="span"
                    display="inline"
                    variant="h3"
                    color={plan.name === 'Basic' ? "textSecondary" : "inherit"}
                  >
                    ${plan.price}
                  </Typography>
                  <Typography
                    component="span"
                    display="inline"
                    variant="subtitle2"
                    color={plan.name === 'Basic' ? "textSecondary" : "inherit"}
                  >
                    /month
                  </Typography>
                </div>
                <Typography
                  variant="overline"
                  color={plan.name === 'Basic' ? "textSecondary" : "inherit"}
                >
                  Max {plan.phoneNumbers} Phone Number
                  <br />
                  {plan.phoneNumberPrice} $/Phone Number
                  <br />
                  {plan.callPrice} $/Minute, {plan.smsPrice} $/SMS
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
                <Typography
                  variant="body2"
                  color={plan.name === 'Basic' ? "textSecondary" : "inherit"}
                >
                  {plan.confMembers} Members/Conference
                  <br />
                  {plan.conference} Conferences at a time
                  <br />
                  {plan.flows} Call Flows
                  <br />
                  {plan.contacts} Contacts
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
              </Paper>
            </Grid>
          })}
        </Grid>
      </Container>

    </div>
  );
};

CTA.propTypes = {
  className: PropTypes.string
};

export default CTA;

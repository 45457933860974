import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component; 
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [ 
  {
    exact: true,
    path: '/password-recovery',
    component: lazy(() => import('src/views/auth/PasswordRecovery'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  }, 
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  }, 
  {
    path: '/admin',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/admin/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/admin/management/customers',
        component: lazy(() => import('src/views/customer/CustomerListView'))
      },
      {
        exact: true,
        path: '/admin/management/customers/:customerId',
        component: lazy(() => import('src/views/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/admin/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/customer/CustomerEditView'))
      }, 
      {
        exact: true,
        path: '/admin/management/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        exact: true,
        path: '/admin/management/pricing/:planId',
        component: lazy(() => import('src/views/pricing/PricingEdit'))
      },
      {
        exact: true,
        path: '/admin/numbers',
        component: lazy(() => import('src/views/numbers/NumbersListView'))
      },
      {
        exact: true,
        path: '/admin/numbers/:numSID/configure',
        component: lazy(() => import('src/views/numbers/NumbersEditAdmin')) 
      },
      {
        exact: true,
        path: '/admin/numbers/buy',
        component: lazy(() => import('src/views/numbers/NumbersCreateView'))
      },
      {
        exact: true,
        path: '/admin/numbers/buy/:phoneNUmber',
        component: lazy(() => import('src/views/numbers/NumbersPurchaseView'))
      }
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/account/:tab',
        component: lazy(() => import('src/views/account/AccountView'))
      }, 
      {
        exact: true,
        path: [
          '/app/chat/new',
          '/app/chat/:threadKey'
        ],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      }, 
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/customer/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() => import('src/views/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/customer/CustomerEditView'))
      }, 
      {
        exact: true,
        path: '/app/management/conferences',
        component: lazy(() => import('src/views/conference/ConferenceListView'))
      },
      {
        exact: true,
        path: '/app/management/conferences/create',
        component: lazy(() => import('src/views/conference/ConferenceCreateView'))
      },
      {
        exact: true,
        path: '/app/management/conferences/:conferenceId',
        component: lazy(() => import('src/views/conference/ConferenceDetailsView'))
      },
      {
        exact: true,
        path: '/app/contacts',
        component: lazy(() => import('src/views/contacts/ContactListView'))
      },
      {
        exact: true,
        path: '/app/contacts/add',
        component: lazy(() => import('src/views/contacts/ContactCreateView'))
      },
      {
        exact: true,
        path: '/app/contacts/:contactId',
        component: lazy(() => import('src/views/contacts/ContactDetailsView'))
      },
      {
        exact: true,
        path: '/app/contacts/:contactId/edit',
        component: lazy(() => import('src/views/contacts/ContactEditView'))
      },
      {
        exact: true,
        path: '/app/numbers',
        component: lazy(() => import('src/views/numbers/NumbersListView'))
      },
      {
        exact: true,
        path: '/app/numbers/:numSID/configure',
        component: lazy(() => import('src/views/numbers/NumbersEditView'))
      },
      {
        exact: true,
        path: '/app/numbers/buy',
        component: lazy(() => import('src/views/numbers/NumbersCreateView'))
      },
      {
        exact: true,
        path: '/app/numbers/buy/:phoneNUmber',
        component: lazy(() => import('src/views/numbers/NumbersPurchaseView'))
      },
      {
        exact: true,
        path: '/app/groups',
        component: lazy(() => import('src/views/groups/GroupListView'))
      },
      {
        exact: true,
        path: '/app/groups/create',
        component: lazy(() => import('src/views/groups/GroupCreateView'))
      },
      {
        exact: true,
        path: '/app/groups/:groupId',
        component: lazy(() => import('src/views/groups/GroupDetailsView')),
      },
      {
        exact: true,
        path: '/app/groups/sms/:Id',
        component: lazy(() => import('src/views/groups/GroupReportView'))
      },
      {
        exact: true,
        path: '/app/groups/:groupId/conference',
        component: lazy(() => import('src/views/groups/GroupConference')),
      },
      {
        exact: true,
        path: '/app/incomingLogs',
        component: lazy(() => import('src/views/callLog')),
      },
      {
        exact: true,
        path: '/app/voicemail',
        component: lazy(() => import('src/views/voicemail')),
      },
      {
        exact: true,
        path: '/app/npx',
        component: lazy(() => import('src/views/npx'))
      },
      {
        exact: true,
        path: '/app/npx/:npxId',
        component: lazy(() => import('src/views/npx/NpxReportView'))
      },
      {
        exact: true,
        path: '/app/flows',
        component: lazy(() => import('src/views/flows/FlowsList'))
      },
      {
        exact: true,
        path: '/app/flows/:flowId',
        component: lazy(() => import('src/views/flows/FlowsView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        // component: lazy(() => import('src/views/reports/DashboardView'))
        component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }, 
  {
    path: '*',
    layout: MainLayout,
    routes: [ 
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
]; 
export default routes ;
